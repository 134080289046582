import React from 'react'
import './Loader.css'

export default function Loader() {
  return (
    <div className="spinner-container">
       <p className="text-center"><i className="fa fa-spinner fa-spin fa-5x"></i> Loading...</p>
    </div>
  )
}

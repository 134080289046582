import React, { useEffect, useState } from "react";
import Header from "../Header/Header";


function CallBack() {
  const [token, setToken] = useState(0);
  
  let url = window.location.href;
  let name = "access_token";
  let accesscode='';
  const getToken=()=>{
    if (!localStorage.getItem("isloaded")) {
      window.location.replace(process.env.REACT_APP_LOGIN);
      localStorage.setItem("isloaded", true);
    }   
    if (url.includes(name)) {
       accesscode = window.location.href.split("&");
      accesscode = accesscode[0].split("=");
      accesscode = accesscode[accesscode.length - 1];
    }
    setToken(accesscode)
  }
  if (!url.includes(name)) {
    localStorage.clear ();
  }
  useEffect(() => {
    getToken()
  });
 
  return (
    <>    
      {token!==0?<Header token={token}/>:null}
      
    </>
  );
}
export default CallBack;
import React from "react";

export default function PreadOnlyRow({ item, portfolioeditHandler }) {
  return (
    <tr key={item.pcR_FD_PTF_ID}>
      <td>
        <button
          className="customButton"
          onClick={(event) =>
            portfolioeditHandler(
              event,
              item.pcR_FD_PTF_ID,
              item.diV_GRP_CD,
              item.diV_PAY_DT_CD,//date
              item.diV_CALC_FQCY_CD//frequency
            )
          }
        >
          <u>Edit</u>
        </button>
      </td>
      <td>{item.exT_PRC_FUND_NMBR}</td>
      <td>{item.fD_PTF_NM}</td>
      <td>{item.diV_GRP_TXT}</td>
      <td>{item.cgDIVIDEND_FQCY_TXT}</td>
      <td>{item.cgPAY_DT_TXT}</td>
      <td>{item.divDIVIDEND_FQCY_TXT}</td>
      <td>{item.divPAY_DT_TXT}</td>
    </tr>
  );
}

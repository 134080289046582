import React from "react";
import { useState } from "react";
import GroupDetails from "../GroupDetails/GroupDetails";
import PortfolioDetails from "../PortfolioDetails/PortfolioDetails";
import "./Header.css";

export default function Header({token}) {
  const [groupDetails, setGroupDetails] = useState(true);
  const [portfolioDetail, setPortfolioDetails] = useState();
 
  const handleClick = (val, e) => {
    e.preventDefault();
    switch (val) {
      case 1:
        setGroupDetails(true);
        setPortfolioDetails(false);
        break;
      case 2:
        setGroupDetails(false);
        setPortfolioDetails(true);
        break;
      default:
        break;
    }
  };
 
  return (
    <div className="mainContainer">
      <div className="logoContainer">
        <img
          className="svg-logo-right"
          src="https://www.principalcdn.com/css/horizon/v2/logo-white-right.svg"
          alt="white right logo"
          style={{ height: "100px" }}
        />
      </div>
      <ul className="nav nav-pills">
        <li
          role="presentation"
          className={groupDetails ? "active" : ""}
          onClick={(e) => handleClick(1, e)}
        >
          <a href="/">Group Details</a>
        </li>
        <li
          role="presentation"
          className={portfolioDetail ? "active" : ""}
          onClick={(e) => handleClick(2, e)}
        >
          <a href="/">Portfolio Details</a>
        </li>
      </ul>
      <div>
        {groupDetails ? (
          <GroupDetails token={token} />
        ) : portfolioDetail ? (
          <PortfolioDetails token={token} />
        ) : (
          <div></div>
        )}
      </div>
      <div
        style={{
          textAlign: "center",
          margin: "10px 0px 10px 0px",
          fontSize: "10px",
          color: "#035fa4",
        }}
      >
        <div>
          Copyright © 2022 The Principal Financial Group. All rights reserved.
        </div>
        <div>
          Products and services offered through Principal Life Insurance Company
          (The Principal), its subsidiaries and affiliates
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "./EditableRow.css";

export default function GeditableRow({
  item,
  handleEditGroupDetails,
  handleCancel,
  handleSave,
  editGroupData,
  handleDeactivation
}) {
  return (
    <tr key={item.diV_GRP_CD}>
      <td>
       { item.diV_GRP_END_DT?"":<div> <button className="customButton" onClick={(e) => handleSave(e, item)}>
          <u>Save</u>
        </button>
        <button className="customButton" onClick={handleCancel}>
          <u>cancel</u>
        </button>
        </div>
       }
      </td>
      <td>
      { item.diV_GRP_END_DT?item.diV_GRP_TXT:
        <input
          type="text"
          value={editGroupData}
          onChange={(e) => handleEditGroupDetails(e)}
          
        />}
      </td>
      <td>{item.diV_GRP_STRT_DT}</td>
      <td>{item.diV_GRP_END_DT?item.diV_GRP_END_DT:<button className="customButton" onClick={(e) =>handleDeactivation(e,item)}>
          <u>Deactivate</u>
        </button>}
       
      </td>
    </tr>
  );
}

import React from "react";

export default function PeditableRow({
  item,
  handleGroupDescription,
  groupdesc,
  divPayfq,
  divPayDat,
  portfolioCancelHandler,
  handleDivPayDate,
  handleDivPayFrequency,
  portfoliohandleSave,
  divPayFqData,
  divpayDateData,
  groupDescdata
}) {

  return (
    <tr key={item.pcR_FD_PTF_ID}>
      <td>
        <button className="customButton"onClick={(e) => portfoliohandleSave(e, item)}>
          <u>Save</u>
        </button>
        <button
          className="customButton"
          onClick={(e) => portfolioCancelHandler(e)}
        >
          <u>cancel</u>
        </button>
      </td>
      <td>{item.exT_PRC_FUND_NMBR}</td>
      <td>{item.fD_PTF_NM}</td>
      <td>
        <select value={groupdesc} onChange={(e) => handleGroupDescription(e)}>
        {groupDescdata.length > 0 &&
          groupDescdata.map(groupdescription => {
            return (
              <option 
                value={groupdescription.diV_GRP_CD}
                key={groupdescription.diV_GRP_CD}
              >
                {groupdescription.diV_GRP_TXT}
              </option>
            );
          })}
        </select>
      </td>
      <td>{item.cgDIVIDEND_FQCY_TXT}</td>
      <td>{item.cgPAY_DT_TXT}</td>
      <td>
      <select value={divPayfq} onChange={(e) => handleDivPayFrequency(e)}>
        {divPayFqData.length > 0 &&
          divPayFqData.map(dividentPayFrequency => {
            return (
              <option 
                value={dividentPayFrequency.dividenD_FQCY_CD}
                key={dividentPayFrequency.dividenD_FQCY_CD}
              >
                {dividentPayFrequency.dividenD_FQCY_TXT}
              </option>
            );
          })}
        </select>
      </td>
      <td>
      
         <select value={divPayDat} onChange={(e) =>   handleDivPayDate(e)}>
        {divpayDateData.length > 0 &&
          divpayDateData.map(dividentPayDate=> {
            return (
              <option 
                value={dividentPayDate.paY_DT_CD}
                key={dividentPayDate.paY_DT_CD}
              >
                {dividentPayDate.paY_DT_TXT}
              </option>
            );
          })}
        </select>
      </td>
    </tr>
  );
}

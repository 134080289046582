import React from "react";

export default function GReadonlyRow({ item, editHandler }) {
  return (
    <tr key={item.diV_GRP_CD}>
      <td>
      {item.diV_GRP_END_DT ?  "":<button
          className="customButton"
          onClick={(event) => editHandler(event, item)}
        >
          <u>Edit</u>
        </button>
        }
      </td>
      <td>{item.diV_GRP_TXT}</td>
      <td>{item.diV_GRP_STRT_DT}</td>
      <td>{item.diV_GRP_END_DT ? item.diV_GRP_END_DT : ""}</td>
    </tr>
  );
}
